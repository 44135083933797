export const colors = {
    white: '#fff',
    black: '#1A1A1A',
    blackHighContrast: 'rgba(0, 0, 0, 0.9)',
    blackLowContrast: 'rgba(0, 0, 0, 0.6)',
    main: '#000',
    pinkAmour: '#f6e6f2',
    gray: '#858585',
    darkGray: '#575757',
    silver: '#ccc',
    border: '#ccc',
    silverChalice: '#aeaeae',
    grayAlto: '#d1d1d1',
    grayAltoDark: '#cecece',
    grayAlabaster: '#f7f7f7',
    grayAthens: '#e6e8ed',
    grayCultured: '#F4F4F4',
    greenPersian: '#36b587',
    secondary: '#36b587',
    greenNasty: '#6eb243',
    berry: '#a51780',
    mintCream: '#E7FAF3',
    palePurple: '#FFE4FA',
    paypoGreen300: '#7EE4BD',
    paypoGreen400: '#54DBA7',
    paypoGreen500: '#2CD091',
    paypoGreen600: '#22A271',
    paypoGreen700: '#187350',
    paypoDarkGreen: '#187350',
    paypoLigthtGrey: '#E6E6E6',
    fuchsia500: '#A70584',
    yellow500: '#FAC42F',
    paypoInactive: '#B2B2B2',
    paypoShadowGrey01: '0px 2px 16px rgba(0, 0, 0, 0.02), 0px 2px 7px rgba(0, 0, 0, 0.0402), 0px 1px 3.5px rgba(0, 0, 0, 0.04), 0px 1px 2.00345px rgba(0, 0, 0, 0.0398), 0px 0px 1px rgba(0, 0, 0, 0.05), 0px 0px 0.5px rgba(0, 0, 0, 0.06)',
    paypoShadowGrey03: '0px 22px 50px rgba(0, 0, 0, 0.02), 0px 12px 30px rgba(0, 0, 0, 0.02), 0px 8px 10px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.1)',
    paypoShadowGrey06: '0px 72px 80px rgba(0, 0, 0, 0.03), 0px 28px 56px rgba(0, 0, 0, 0.05), 0px 24px 22px rgba(0, 0, 0, 0.05), 0px 8px 6px rgba(0, 0, 0, 0.04), 0px 1px 3px rgba(0, 0, 0, 0.06), 0px 0px 1px rgba(0, 0, 0, 0.1)',
};

export const breakpoints = {
    small: '500px',
    popupMedium: '548px',
    medium: '768px',
    big: '1024px',
    large: '1140px',
    extraLarge: '1280px',
};
